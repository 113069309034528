<template>
  <v-select
    :label="label"
    :rules="required ? isRequired : []"
    :value="value"
    :required="required"
    :disabled="disabled"
    :persistent-hint="!!info"
    :hint="info"
    item-value="value"
    item-text="label"
    :items="JSON.parse(JSON.stringify(options))"
    @change="$emit('change', {val: $event, name: name})"
  />
</template>
<script>
  import defaultProps from './props.js'
  export default {
    props: [...defaultProps.default],
    data () {
      return {
        content: null,
        isRequired: [
          (value) => {
            if (value?.length > 0) return true
            return `${this.label || this.name} ist erforderlich.`
          },
        ],
      }
    },
  }
</script>
